<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Accordion</Heading>
			</GridContainer>
			<GridContainer size="8">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Example</Heading>
							<Paragraph>When using multiple accordions all together, it is suggested to remove the top accordions border with border="none".</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Accordion label="First Accordion" border="none">
								<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
							</Accordion>
							<Accordion label="Second Accordion">
								<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
							</Accordion>
							<Accordion label="Third Accordion">
								<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
							</Accordion>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Accordion Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">label</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">
										Defines the descriptive text inside the accordion header.
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">border</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">'none'</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">
										Removes the border from the top of the accordion.
									</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
}
</script>

<style lang="scss" scoped>
</style>
