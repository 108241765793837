<template>
	<Page layout="sidebar">
		<Header>
			<Stack space="2">
				<Stack space="2">
					<Icon icon="line_axis" size="medium" color="primary" background="light" />
					<Heading size="3" margin="none" whiteout>AXIOM</Heading>
				</Stack>
				<Stack space="3" justify="center" width="auto" visibility="show hide@md" v-click-outside="setClose">
					<Link @click.native="setOpen()" display="inline" url="">
						<Icon icon="menu" color="light" size="large" v-if="!isOpen" />
						<Icon icon="menu_open" color="light" size="large" v-if="isOpen" />
					</Link>
					<Drawer :open="isOpen">
						<Stack direction="column" align="left" space="3">
							<Stack direction="column" align="left" space="2">
								<Paragraph bold micro>Menu</Paragraph>
								<Link url="/design-system" icon="dashboard" text="Dashboard" color="dark"></Link>
							</Stack>
							<Stack direction="column" align="left" space="2">
								<Paragraph bold micro>Foundations</Paragraph>
								<Link url="/component/color" icon="palette" text="Color" color="dark"></Link>
								<Link url="/component/icon" icon="favorite_outline" text="Icon" color="dark"></Link>
								<Link url="/component/theme" icon="format_color_fill" text="Themes" color="dark"></Link>
								<Link url="/component/typography" icon="text_fields" text="Typography" color="dark"></Link>
							</Stack>
							<Stack direction="column" align="left" space="2">
								<Paragraph bold micro>Components</Paragraph>
								<Link url="/component/accordion" icon="view_list" text="Accordion" color="dark"></Link>
								<Link url="/component/button" icon="view_agenda" text="Button" color="dark"></Link>
								<Link url="/component/card" icon="recent_actors" text="Card" color="dark"></Link>
								<Link url="/component/form" icon="dns" text="Form" color="dark"></Link>
								<Link url="/component/grid" icon="grid_view" text="Grid" color="dark"></Link>
								<Link url="/component/image" icon="image" text="Image" color="dark"></Link>
								<Link url="/component/link" icon="link" text="Link" color="dark"></Link>
								<!-- <Link url="/component/overlay" text="Overlay"></Link> -->
								<Link url="/component/page" icon="space_dashboard" text="Page" color="dark"></Link>
								<Link url="/component/stack" icon="view_module" text="Stack" color="dark"></Link>
							</Stack>
						</Stack>
					</Drawer>
				</Stack>
			</Stack>
		</Header>
		<Sidebar>
			<Stack direction="column" align="left" space="3">
				<Stack direction="column" align="left" space="2">
					<Paragraph bold micro>Menu</Paragraph>
					<Link url="/design-system" icon="dashboard" text="Dashboard" color="dark"></Link>
				</Stack>
				<Stack direction="column" align="left" space="2">
					<Paragraph bold micro>Foundations</Paragraph>
					<Link url="/component/color" icon="palette" text="Color" color="dark"></Link>
					<Link url="/component/icon" icon="favorite_outline" text="Icon" color="dark"></Link>
					<Link url="/component/theme" icon="format_color_fill" text="Themes" color="dark"></Link>
					<Link url="/component/typography" icon="text_fields" text="Typography" color="dark"></Link>
				</Stack>
				<Stack direction="column" align="left" space="2">
					<Paragraph bold micro>Components</Paragraph>
					<Link url="/component/accordion" icon="view_list" text="Accordion" color="dark"></Link>
					<Link url="/component/button" icon="view_agenda" text="Button" color="dark"></Link>
					<Link url="/component/card" icon="recent_actors" text="Card" color="dark"></Link>
					<Link url="/component/form" icon="dns" text="Form" color="dark"></Link>
					<Link url="/component/grid" icon="grid_view" text="Grid" color="dark"></Link>
					<Link url="/component/image" icon="image" text="Image" color="dark"></Link>
					<Link url="/component/link" icon="link" text="Link" color="dark"></Link>
					<!-- <Link url="/component/overlay" text="Overlay"></Link> -->
					<Link url="/component/page" icon="space_dashboard" text="Page" color="dark"></Link>
					<Link url="/component/stack" icon="view_module" text="Stack" color="dark"></Link>
				</Stack>
			</Stack>
		</Sidebar>
		<Body>
			<slot></slot>
		</Body>
		<Footer>
			<Stack direction="column row@sm"  justify="center spaceBetween@sm">
				<Paragraph margin="none">2021 © Axiom Design System</Paragraph>
				<Paragraph margin="none">Designed &amp; Developed by <Link display="inline" url="/" newTab>Anna Robbins</Link></Paragraph>
			</Stack>
		</Footer>
	</Page>
</template>

<script>
export default {
  data() {
    return {
			theme: 'theme-ds',
			isOpen: false,
		}
  },
	methods: {
		setOpen(){
			this.isOpen = !this.isOpen;
		},
    setClose() {
      this.isOpen = false
    },
	}
}
</script>

<style lang="scss">
  .controller{
    padding-top:35px;
  }
  .fixed-bar{
    position:fixed;
    top:0;
    left:0;
    width:calc(100% - 16px);
    padding:8px;
    background:rgb(37, 37, 37);
    z-index:9;
  }
</style>
